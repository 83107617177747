<template>
  <v-dialog
    v-model="dialog"
    :width="$vuetify.breakpoint.smAndDown ? '100%' : '30%'"
  >
    <template v-slot:activator="{ on: onDialog }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn
            elevation="0"
            @click="open"
            v-on="{ ...onDialog, ...onTooltip }"
            :block="block"
            style="height: 30px"
            icon
            :large="large"
            :small="!large"
            :disabled="disabled"
          >
            <v-icon :large="large" :small="!large">mdi-plus-circle</v-icon>
          </v-btn>
        </template>
        {{ $t("add", { name: "" }) }}
      </v-tooltip>
    </template>
    <v-card id="desing">
      <v-card-title
        style="text-decoration: underline; text-transform: uppercase"
        >{{
          $t(edit ? "edit" : "add", { name: $tc("tattooers.token_payment") })
        }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="design-form"
        @submit.prevent="validateForm('design-form')"
      >
        <v-card-text>
          <v-row justify="center">
            <v-col class="py-0" cols="8">
              <label v-text="$tc('expenses.concepto')"></label>
              <v-select
                :items="token_payments"
                v-model="form.size"
                outlined
                hide-details
                dense
                item-text="size"
                return-object
                @input="select"
              ></v-select>
            </v-col>

            <v-col class="py-0" cols="8">
              <label v-text="$t('designs.token_payment')"></label>
              <number-input-with-separators
                :value="form"
                valueKey="token_payment"
                ref="money2"
                rules="required"
                hide-details
                outlined
                dense
              ></number-input-with-separators>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="
              dialog = false;
              files = [];
            "
            style="height: 25px"
            elevation="0"
            class="mr-1"
            >{{ $t("cancel") }}</v-btn
          >

          <v-btn
            style="color: #363533; height: 25px; padding-top: 10px !important"
            elevation="0"
            type="submit"
            class="mr-1 ml-0"
            @click="accion = 'save'"
            ><v-icon style="margin-right: 5px" size="14px">$save</v-icon
            >{{ $t("save", { name: "" }) }}</v-btn
          >
          <v-btn
            style="color: #363533; height: 25px; padding-top: 10px !important"
            elevation="0"
            type="submit"
            @click="accion = 'send'"
            class="ml-0"
            ><v-icon style="margin-right: 5px" size="14px">$send</v-icon>
            {{ $t("send", { name: "" }) }}
          </v-btn>
          <SendMail ref="sendMail" hidden :appointment="appointment" />
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import FileUpload from "vue-upload-component";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      dialog_customer: false,
      files: [],
      form: {},
      token_payments: [],
      customer_id: null,
      edit: false,
      tokenPayment: {},
      accion: "send",
    };
  },
  props: ["appointment", "sessions", "block", "disabled", "large"],
  computed: {
    payment_appointment() {
      let s = this.appointment.studio.user.setting.find(
        (x) => x.key === "payment_appointment"
      );
      if (s) return s.value === "1";
      return false;
    },
  },
  components: {
    FileUpload,
    NumberInputWithSeparators: () =>
      import("@/components/ui/NumberInputWithSeparators"),
    SendMail: () => import("@/components/mails/SendMail"),
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.fetch();
        }
      },
      deep: true,
    },
  },
  methods: {
    editTokenPayment(tokenPayment) {
      this.form = {
        id: tokenPayment.id,
        size: tokenPayment.concepto,
        token_payment: tokenPayment.price,
      };
      this.edit = true;
      this.dialog = true;
    },
    ...mapActions("appointments", [
      "addAppointmensDesignTokenPayment",
      "getAppoinmentsTokenPayments",
      "editAppointmentPaymentToken",
    ]),

    ...mapActions("tattooers", ["getTokenPayments"]),
    fetch() {
      if (this.appointment.tattooer)
        this.getTokenPayments(this.appointment.tattooer.id).then((data) => {
          this.token_payments = data;
          this.token_payments.push({ size: this.$t("other_valor") });
        });
      else {
        this.token_payments = [{ size: this.$t("other_valor") }];
      }
    },
    select() {
      if (this.form.size.type === "percentage") {
        let porcentaje = (this.form.price * this.form.size.price) / 100;
        porcentaje = Math.round(porcentaje * 100) / 100;
        this.form.token_payment = porcentaje;
      } else {
        this.form.token_payment = this.form.size.price;
      }
      this.$refs.money2.change();
    },
    async validateForm(scope) {
      this.errors.clear();
      this.userProcessed = false;
      let result = await this.$validator.validateAll(scope);
      let money_val_1 = true;
      let money_val_2 = true;
      let money_val_3 = true;
      if (this.$refs.money1) money_val_1 = await this.$refs.money1.validate();
      if (this.$refs.money2) money_val_2 = await this.$refs.money2.validate();
      if (this.$refs.money3) money_val_3 = await this.$refs.money3.validate();

      if (result && money_val_1 && money_val_2 && money_val_3) {
        //console.log("VALIDADO");
        let formData = new FormData();
        for (let key in this.form) {
          switch (key) {
            case "size":
              formData.append(key, this.form[key].size);
              break;
            default:
              formData.append(key, this.form[key]);
          }
        }

        formData.append("appointment_id", this.appointment.id);
        this.files.forEach((file) => {
          formData.append("files[]", file.file);
        });
        formData.append("customer_id", this.customer_id);
        console.log(
          this.appointment.extra_customers.length <= 1,
          this.customer_id
        );

        if (!this.edit) {
          this.addAppointmensDesignTokenPayment(formData).then((data) => {
            console.log("ADD APP DESIGNT TOKEN", data);
            this.dialog = false;
            this.dialog_customer = false;
            this.$store.dispatch("appointments/getPendingAppointments");
            this.$emit("update");
            if (this.accion == "send") {
              console.log("TP", this.form);
              this.$refs.sendMail.open("PAGA  e SEÑAL");
              this.$refs.sendMail.signal_selected = data.id;
            }
          });
        } else {
          console.log("FORMEDIT", this.form);
          this.editAppointmentPaymentToken({
            formData: this.form,
            appointment_payment_token_id: this.form.id,
          }).then((data) => {
            console.log("EDIT APP DESIGNT TOKEN");
            this.$emit("update");
            if (this.accion == "send") {
              console.log("TP", this.form.id);
              this.$refs.sendMail.open("PAGA Y SEÑAL");
              this.$refs.sendMail.signal_selected = this.form.id;
            }
            this.dialog = false;
          });
        }
      }
    },
    open() {
      this.form = {};
      if (this.$refs.money1) this.$refs.money1.temp = null;
      if (this.$refs.money2) this.$refs.money2.temp = null;
      if (this.$refs.money3) this.$refs.money3.temp = null;
      this.edit = false;
    },
  },
};
</script>
<style lang="sass">
#desing
  .file-upload
    label
      height: 70px
  label
    height: 25px
    display: block

.upload
  *
    cursor: pointer
  label
    height: 100% !important
</style>
